const backgroundImagesPool = [
    { src: require('../../../../../images/ladybug.webp')},
    { src: require('../../../../../images/background1.webp')},
    { src: require('../../../../../images/background2.webp')},
    { src: require('../../../../../images/background3.webp')},
    { src: require('../../../../../images/background4.webp')},
    { src: require('../../../../../images/background5.webp')},
    { src: require('../../../../../images/background6.webp')},
    { src: require('../../../../../images/background7.webp')},
    { src: require('../../../../../images/background8.webp')},
    { src: require('../../../../../images/background9.webp')},
    { src: require('../../../../../images/background10.webp')},
    { src: require('../../../../../images/background11.webp')},
    { src: require('../../../../../images/background12.webp')},
    { src: require('../../../../../images/background13.webp')},
    { src: require('../../../../../images/background14.webp')},
    { src: require('../../../../../images/background15.webp')},
    { src: require('../../../../../images/background16.webp')},
    { src: require('../../../../../images/background17.webp')},
    { src: require('../../../../../images/background18.webp')},
    { src: require('../../../../../images/background19.webp')},

];

export default backgroundImagesPool;